const textEffect = 'ЛДПР выдвинула кандидатов в губернаторы и депутаты в 20 регионах. Кликните по карте, чтобы узнать их имена. Будем рады, если вы поддержите нас 13 сентября.';
const container = document.querySelector('#effect');
let x = 0;

function animate() {
    if (x < textEffect.length) {
        container.innerHTML += textEffect.charAt(x);
        x++;
    }
    setTimeout(animate, 20);
}

if (document.documentElement.clientWidth > 600) {
    animate();
}




// function createNumber(num, elem) {
//     const time = 2500; // ms
//     const step = 1;
//     let html = document.querySelector('#'+ elem);
//     let result = 0;
//     let t = Math.round(time/(num/step))

//     let interval = setInterval(() => {
//         result += step;
//         if (result == num) {
//             clearInterval(interval);
//         }
//         html.innerHTML = result;
//     }, t)
// }

// createNumber(20, 'num')