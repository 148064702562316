const depatylist = {
    ynao: {
        url: {
        },
        second: {
            url: {
            },
        },
        third: {
            container: `<div class="third-list__title">
            <h2> Кандидаты ЛДПР в Законодательное собрание Ямало-Ненецкого автономного округа</h2>
        </div>
        <div class="third__wrapper">

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Садовников</span> <br> Денис Владимирович</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Садовников Денис Владимирович.png" alt="Садовников.png">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Пикалов</span> <br> Артём Анатольевич</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Пикалов Артём Анатольевич.png" alt="Пикалов">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Яндо</span> <br> Илья Васильевич</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Яндо Илья Васильевич.png" alt="Яндо">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Калита</span> <br> Дмитрий Анатольевич</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Калита Дмитрий Анатольевич.png" alt="Калита">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Тюрин</span> <br> Олег Владимирович </p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Тюрин Олег Владимирович.png" alt="Тюрин">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Столяров</span> <br> Сергей Викторович</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Столяров Сергей Викторович.png" alt="Столяров">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Смирных</span> <br> Елена Николаевна</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Смирных Елена Николаевна.png" alt="Смирных">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Иванов</span> <br> Артём Алексеевич</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Иванов Артём Алексеевич.png" alt="Иванов">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Земенкова</span> <br> Елена Николаевна</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Земенкова Елена Николаевна.png" alt="Земенкова">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Голубенко</span> <br> Александр Федорович </p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Голубенко Александр Федорович.png" alt="Голубенко">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Воробьев</span> <br>Игорь Владимирович</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Воробьев Игорь Владимирович.png" alt="Воробьев">
                </div>
            </div>
            
            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Бавдурный</span> <br> Дмитрий Александрович</p>
                </div>
                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Бавдурный Дмитрий Александрович.png" alt="Бавдурный">
                </div>
        </div>
            
        </div>`,
        },
    },
    voronej: {
        url: {
        },
        second: {
            url: {
            },
        },
        third: {
            container: `<div class="third-list__title">
            <h2> Кандидаты от ЛДПР в Воронежскую областную думу</h2>
        </div>
        <div class="third__wrapper">

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Шатский</span> <br> А. И.</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Шатский.png" alt="Шатский.png">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Волкова</span> <br> И. В.</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Волкова.png" alt="Волкова">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Воронин</span> <br> Г. Д</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Воронин.png" alt="Воронин">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Гелунов</span> <br> А. А.</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Гелунов.png" alt="Гелунов">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Воронина</span> <br> Д. К. </p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Воронина.png" alt="Воронина">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Джоган</span> <br> А. В.</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Джоган.png" alt="Джоган">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Задорожная</span> <br> А. Ю.</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Задорожная.png" alt="Задорожная">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Заложных</span> <br> Д. Ю. </p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Заложных.png" alt="Заложных">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Камашева</span> <br> И. В. </p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Камашева.png" alt="Камашева">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Кольцов</span> <br> В. И.</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Кольцов.png" alt="Кольцов">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Каневский</span> <br> А. А. </p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Каневский.png" alt="Каневский">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Квитко</span> <br> М. А.</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Квитко.png" alt="Квитко">
                </div>
            </div>
            
            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Кулапина</span> <br> К. А. </p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Кулапина.png" alt="Кулапина">
                </div>
            </div>
            
            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Лаптурова</span> <br> О. В.</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Лаптурова.png" alt="Лаптурова">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Мищенко</span> <br> Е. А.</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Мищенко.png" alt="Мищенко">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Образцов</span> <br> А. В.</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Образцов.png" alt="Образцов">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Овсянников</span> <br> А. А.</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Овсянников.png" alt="Овсянников">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Полубавкина</span> <br> А. Н.</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Полубавкина.png" alt="Полубавкина">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Сабзалиева</span> <br> А. А.</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Сабзалиева.png" alt="Сабзалиева">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Спицына</span> <br> М. А.</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Спицына.png" alt="Спицына">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Стародубов</span> <br> О. В.</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Стародубов.png" alt="Стародубов">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Стародубова</span> <br> У. В.</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Стародубова.png" alt="Стародубова">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Цевовальников</span> <br> А. А.</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Цевовальников.png" alt="Цевовальников">
                </div>
            </div>
            
        </div>`,
        }
    },

    novosib: {
        url: {
        },
        second: {
            url: {

            },
        },
        third: {
            container: `               <div class="third-list__title">
            <h2> Кандидаты от ЛДПР в Новосибирскую Областную Думу
            </h2>
        </div>
        <div class="third__wrapper">

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Щербинина</span> <br> Светлана Даниловна</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Щербинина Светлана Даниловна.png" alt="Щербинина">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Щербак</span> <br>  Александр Александрович</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Щербак Александр Александрович.png" alt="Щербак">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Шевченко</span> <br> Марина Сергеевна</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Шевченко Марина Сергеевна.png" alt="Шевченко">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Шаблинский</span> <br> Евгений Сергеевичб</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Шаблинский Евгений Сергеевич.png" alt="Шаблинский">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Федореев</span> <br> Руслан Сергеевич</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Федореев Руслан Сергеевич.png" alt="Федореев">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Суворов</span> <br> Олег Геннадьевич</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Суворов Олег Геннадьевич.png" alt="Суворов">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Сергеев</span> <br> Денис Юрьевич</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Сергеев Денис Юрьевич.png" alt="Сергеев">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Реутов</span> <br> Виктор Юрьевич</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Реутов Виктор Юрьевич.png" alt="Реутов">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Перевалов</span> <br> Антон Александрович</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Перевалов Антон Александрович.png" alt="Перевалов">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Нестерова</span> <br> Юлия Владимировна</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Нестерова Юлия Владимировна.png" alt="Нестерова">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Нестеренко</span> <br> Виктория Сергеевна</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Нестеренко Виктория Сергеевна.png" alt="Нестеренко">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Муренко</span> <br> Виктория Сергеевна</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Муренко Виктория Сергеевна.png" alt="Муренко">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Маренков</span> <br> Анатолий Григорьевич</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Маренков Анатолий Григорьевич.png" alt="Маренков">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Лесников</span> <br> Александр Михайлович</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Лесников Александр Михайлович.png" alt="Лесников">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Леонтьева</span> <br> Карина Ивановна</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Леонтьева Карина Ивановна.png" alt="Леонтьева">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Красовская</span> <br> Наталия Рудольфовна</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Красовская Наталия Рудольфовна.png" alt="Красовская">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Красов</span> <br> Алексей Иванович</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Красов Алексей Иванович.png" alt="Красов">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Козлова</span> <br> Алёна Юрьевна</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Козлова Алёна Юрьевна.png" alt="Козлова">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Кастальский</span> <br> рий Алексеевич</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Кастальский Юрий Алексеевич.png" alt="Кастальский">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Казаков</span> <br> Роман Антонович</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Казаков Роман Антонович.png" alt="Казаков">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Зыкина</span> <br> Маргарита Валерьевна</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Зыкина Маргарита Валерьевна.png" alt="Зыкина">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Жернов</span> <br> Николай Константинович</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Жернов Николай Константинович.png" alt="Жернов">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Жаркова</span> <br> Анастасия Владимировна</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Жаркова Анастасия Владимировна.png" alt="Жаркова">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Дятлова</span> <br> Надежда Петровна</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Дятлова Надежда Петровна.png" alt="Дятлова">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Гончарова</span> <br> Ксения Владимировна</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Гончарова Ксения Владимировна.png" alt="Гончарова">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Голованев</span> <br> Дмитрий Павлович</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Голованев Дмитрий Павлович.png" alt="Голованев">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Гладышева</span> <br> Елена Владимировна</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Гладышева Елена Владимировна.png" alt="Гладышева">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Гилев</span> <br> Сергей Владимирович</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Гилев Сергей Владимирович.png" alt="Гилев">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Вигилянская</span> <br> Татьяна Александровна</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Вигилянская Татьяна Александровна.png" alt="Вигилянская">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Беляев</span> <br> Сергей Матвеевич</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Беляева Сергей Матвеевич.png" alt="Беляев">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Белькова</span> <br> Екатерина Владимировна</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Белькова Екатерина Владимировна.png" alt="Белькова">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Ардатов</span> <br> Андрей Леонидович</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Ардатов Андрей Леонидович.png" alt="Ардатов">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Александрова</span> <br>Анастасия Анатольевна</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Александровна Анастасия Анатольевна.png" alt="Александрова">
                </div>
            </div>
            

            </div>
        `,
        },
    },
    belgorod: {
        url: {
        },
        second: {
            url: {

            },
        },
        third: {
            container: `<div class="third-list__title">
            <h2> Кандидаты от ЛДПР в Белогородскую Областную Думу
            </h2>
        </div>
        <div class="third__wrapper">

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Богданова</span> <br> Олеся Владимировна</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Богданова Олеся Владимировна.png" alt="Богданова">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Червинский</span> <br>  Богдан Александрович</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Червинский Богдан Александрович.png" alt="Червинский">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Меркулов</span> <br> Денис Валерьевич</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Меркулов Денис Валерьевич.png" alt="Меркулов">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Шкуратов</span> <br> Дмитрий Сергеевич</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Шкуратов Дмитрий Сергеевич.png" alt="Шкуратов">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Малютин</span> <br> Максим Станиславович</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Малютин Максим Станиславович.png" alt="Малютин">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Косова</span> <br> Вера Александровна</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Косова Вера Александровна.png" alt="Косова">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Дрёмов</span> <br> Евгений Александрович</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Дрёмов Евгений Александрович.png" alt="Дрёмов">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Климаневский</span> <br> Константин Александрови</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Климаневский Константин Александрович.png" alt="Климаневский">
                </div>
            </div>
            

            </div>
        `,
        },
    },
    ryzan: {
        url: {
        },
        second: {
            url: {

            },
        },
        third: {
            container: `<div class="third-list__title">
            <h2> Кандидаты от ЛДПР в Рязанскую Областную Думу
            </h2>
        </div>
        <div class="third__wrapper">

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Чубанов</span> <br> Сергей Владимирович</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Чубанов.png" alt="Чубанов">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Хлибутин</span> <br> Александр Александрович</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Хлибутин.png" alt="Хлибутин">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Фадеев</span> <br> Игорь Васильевич</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Фадеев.png" alt="Фадеев">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Усачёв</span> <br> Никита Сергеевич</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Усачёв.png" alt="Усачёв">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Томин</span> <br> Андрей Дмитриевич</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Томин.png" alt="Томин">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Тихонова</span> <br> Елена Николаевна</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Тихонова.png" alt="Тихонова">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Стариков</span> <br> Александр Алексеевич</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Стариков.png" alt="Стариков">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Савина</span> <br> Елена Ивановна</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Савина.png" alt="Савина">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Репников</span> <br> Дмитрий Александрович</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Репников.png" alt="Репников">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Мясин</span> <br> Евгений Владимирович</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Мясин.png" alt="Мясин">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Мустафин</span> <br> Максим Хамитович</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Мустафин.png" alt="Мустафин">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Ликучёв</span> <br> Андрей Александрович</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Ликучёв.png" alt="Ликучёв">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Зацепин</span> <br> Роман Алексеевич</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Зацепин.png" alt="Зацепин">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Голоскоков</span> <br> Ефим Владимирович</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Голоскоков.png" alt="Голоскоков">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Генералов</span> <br> Денис Сергеевич</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Генералов.png" alt="Генералов">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Бойцова</span> <br> Ксения Евгеньевна</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Бойцова.png" alt="Бойцова">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Барков</span> <br> Сергей Иванович</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Барков.png" alt="Барков">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Ахунов</span> <br> Павел Анатольевич</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Ахунов.png" alt="Ахунов">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Аносов</span> <br> Валерий Викторович</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Аносов.png" alt="Аносов">
                </div>
            </div>
           
            
        </div>
        `,
        },
    },
    arkhangelsk: {
        title: "В этом году в Архангельской области выбирают губернатора",
        name: "Кандидат от ЛДПР Пивков Сергей Анатольевич",
        description: "Руководитель фракции ЛДПР в Архангельском областном Собрании депутатов. Доцент кафедры государственного и муниципального управления и менеджмента. Родился в Новодвинске, 36 лет.",
        photo: '<img src="/src/img/pivkov.png" alt="Пивков">',
        url: {
            vk: '<a href="https://vk.com/sapivkov" target="_blank">Вконтакте</a>',
            fb: '<a href="https://ru-ru.facebook.com/sapivkov" target="_blank">Фэйсбук</a>',
            inst: '<a href="https://www.instagram.com/s_a_pivkov/" target="_blank">Инстаграм</a>'
        },
        second: {
            url: {

            },
        }
    },
    komi: {
        title: "В этом году в республике Коми выбирают губернатора",
        name: "Кандидат от ЛДПР Никитин Андрей Андреевич",
        description: "Родился в Сыктывкаре. Депутат муниципального образования ГО «Усинск». Отец двоих детей, 30 лет.",
        photo: '<img src="/src/img/Коми-Губернатор-Никитин.png" alt="Пивков">',
        url: {
            vk: '<a href="https://vk.com/id5686131" target="_blank">Вконтакте</a>',
        },
        second: {
            url: {

            },
        },

        third: {
            container: `<div class="third-list__title">
            <h2> и депутатов Государственного Совета Республики</h2>
        </div>
        <div class="third__wrapper">

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Фатыхов</span> <br> Эдуард Ильдусович</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Коми-ЗС-Фатыхов.png" alt="Фатыхов">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Размыслова</span> <br> Ирина Анатольевна</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Размыслова.png" alt="Размыслова">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Нечаев</span> <br> Александр Владимирович</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Нечаев.png" alt="Нечаев">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Нагаева</span> <br> Галина Юрьевна</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Нагаева.png" alt="Нагаева">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Музалев</span> <br> Юрий Викторович</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Музалев.png" alt="Музалев">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Мошегов</span> <br> Геннадий Владимирович</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Мошегов.png" alt="Мошегов">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Кузнецов</span> <br> Станислав Иванович</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Кузнецов.png" alt="Кузнецов">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Кривощёкова</span> <br> Любовь Сергеевна</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Кривощёкова.png" alt="Кривощёкова">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Гобанов</span> <br> Михаил Алексеевич</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Гобанов.png" alt="Гобанов">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Князев</span> <br> Юрий Викторович</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Князев.png" alt="Князев">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Зиновьева</span> <br> Наталья Геннадьевна</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Зиновьева.png" alt="Зиновьева">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Журавлёв</span> <br> Виталий Михайлович</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Журавлёв.png" alt="Журавлёв">
                </div>
            </div>

            <div class="third__wrapper--container">
                <div class="third__wrapper--left">
                    <p> <span>Вокуева</span> <br> Яна Яновна</p>
                </div>

                <div class="third__wrapper--right">
                    <img  class="third__wrapper--photo" src="/src/img/Вокуева.png" alt="Вокуева">
                </div>
            </div>
           
            
        </div>
        `,
        },
    },
    lenin: {
        title: "В этом году в Ленинградской области выбирают губернатора",
        name: "Кандидат от ЛДПР Лебедев Андрей Ярославович",
        description: "Экс-депутат Государственной думы, депутат Законодательного собрания Ленинградской области. Женат, воспитывает двоих детей.",
        photo: '<img src="/src/img/Ленобласть-Губернатор-Лебедев.png" alt="Лебедев">',
        url: {
        },
        second: {
            url: {

            },
        }
    },
    yamal: {
        title: "В этом году в республике  выбирают депутатов Заксобрания",
        name: "Кандидаты от ЛДПР ",
        description: "Здесь будут кандидаты от ЛДПР",
        url: {
            vk: "vk.com",
            fb: "fb.com",
            inst: "null",
        },
        second: {
            url: {

            },
        }
    },

    kaluga: {
        title: "В этом году в Калужской области выбирают губернатора",
        name: "Кандидат от ЛДПР Опарышев Степан Степанович",
        description: "Родился в Обнинске, 33 года. Координатор Обнинского местного отделения ЛДПР, начальник отдела развития ООО «МироСтрой».",
        photo: '<img src="/src/img/Калуга-Губернатор-Опарышев.png" alt="Лебедев">',
        url: {
        },
        second: {
            url: {

            },
        }
    },
    kostroma: {
        title: "В этом году в Костромской области выбирают губернатора",
        name: "Кандидат от ЛДПР Федоров Руслан Владимирович",
        description: "30 лет, окончил Костромской государственный технологический университет и Институт Мировых Цивилизаций. Руководитель Костромского отделения ЛДПР.",
        photo: "<img src='/src/img/Фёдоров.svg' alt='Фёдоров'>",
        url: {
            vk: '<a href="https://vk.com/rus44rus" target="_blank">Вконтакте</a>',
            fb: '<a href="https://www.facebook.com/rus44rus" target="_blank">Фэйсбук</a>',
            inst: '<a href="https://www.instagram.com/fedorov44rus/" target="_blank">Инстаграм</a>',
        },
        second: {
            url: {

            },
        }
    },
    smolensk: {
        title: "В этом году в Смоленской области выбирают губернатора",
        name: "Кандидат от ЛДПР Алексей Владимирович Островский",
        description: "Губернатор Смоленской области, экс-депутат Госдумы, женат, воспитывает двух детей. Член Высшего совета ЛДПР.",
        photo: "<img src='/src/img/Смоленск-Губернатор-Островский.png' alt='Островский'>",
        url: {
            vk: '<a href="https://vk.com/ostrovskylive1" target="_blank">Вконтакте</a>',
            inst: '<a href="https://www.instagram.com/always_open_heart/" target="_blank">Инстаграм</a>',
        },
        second: {
            url: {

            },
        }
    },
    tambov: {
        title: "В этом году в Тамбовской области выбирают главу администрации",
        name: "Кандидат от ЛДПР Телегин Игорь Вячеславович",
        description: "Депутат Тамбовской областной Думы, заместитель председателя комитета по бюджету, налогам и финансам. 18 лет состоит в ЛДПР.",
        photo: '<img src="/src/img/Тамбов-Губернатор-Телегин.png" alt="Телегин">',
        url: {
        },
        second: {
            url: {

            },
        }
    },
    kursk: {
        title: "В этом году в Курской области выбирают депутата Государственной думы",
        name: "Кандидат от ЛДПР Томанов Алексей Юрьевич",
        description: "39 лет, координатор Курского регионального отделения ЛДПР, депутат Курского городского Собрания депутатов.Женат, воспитывает двух детей. Имеет высшее образование, 16 лет состоит в ЛДПР.",
        photo: '<img src="/src/img/Томанов.svg" alt="Томанов">',
        url: {
            vk: '<a href="https://vk.com/tomanovldpr" target="_blank">Вконтакте</a>',
        },
        second: {
            url: {

            },
        }
    },
    yaroslav: {
        title: "В этом году в Ярославской области выбирают депутата Государственной Думы",
        name: "Кандидат от ЛДПР Лобанова Ирина Валерьевна ",
        description: "51 год, депутат Ярославской областной Думы. 22 года состоит в ЛДПР.",
        photo: '<img src="/src/img/Ярославль-ГД-Лобанова.png" alt="Лобанова">',
        url: {
            vk: '<a href="https://vk.com/id91284005" target="_blank">Вконтакте</a>',
        },
        second: {
            url: {

            },
        }
    },
    magadan: {
        title: "В этом году в Магаданском крае выбирают депутатов Законодательного собрания края",
        name: "Кандидат от ЛДПР Виталий Ненашев",
        description: "47 лет, получил два высших образования в Сибирском Государственном Университете",
        photo: '<img src="/src/img/Магадан-ЗС-Ненашев.png" alt="Ненашев">',
        url: {

        },
        second: {
            url: {

            },
        }
    },
    irkutsk: {
        title: "В этом году в Иркутской области выбирают губернатора",
        name: "Кандидат от ЛДПР Духовников Андрей Михайлович",
        description: "Координатор Иркутского регионального отделения ЛДПР, депутат Ушаковского муниципального образования Иркутской области. Имеет высшее образование, женат, воспитывает двух детей.",
        photo: '<img src="/src/img/Иркутск-Губернатор-Духовников 1.png" alt="Духовников">',
        url: {
            vk: '<a href="https://vk.com/id472460022" target="_blank">Вконтакте</a>',
        },
        second: {
            url: {

            },
        }
    },
    penza: {
        title: "В этом году в Пензенской области выбирают губернатора",
        name: "Кандидат от ЛДПР Васильев Александр Евгеньевич",
        description: "Руководитель фракции ЛДПР в Законодательном Собрании Пензенской области. Женат, воспитывает двух детей",
        photo: '<img src="/src/img/Пенза-Губернатор-Васильев.png" alt="Васильев">',
        url: {
            vk: '<a href="https://vk.com/vasilev5800" target="_blank">Вконтакте</a>',
            fb: '<a href="https://facebook.com/profile.php?id=100003613715285" target="_blank">Фэйсбук</a>',
            inst: '<a href="https://instagram.com/vasilev58/" target="_blank">Инстаграм</a>'
        },
        second: {
            title: "И выборы депутата Государственной думы",
            name: "Кандидат от ЛДПР Сердовинцев Вадим Юрьевич",
            description: "42 года, имеет высшее образование. Депутат Собрания представителей г. Нижний Ломов Нижнеломовского района. В ЛДПР состоит 8 лет.",
            photo: '<img src="/src/img/Пенза-ГД-Сердовинцев.png" alt="Сердовинцев">',
            url: {
                vk: '<a href=https://vk.com/larzs target="_blank">Вконтакте</a>',
                fb: '<a href="https://www.youtube.com/channel/UCSmXbjTykhCk3NqMWH8ZdCw" target="_blank">Ютуб</a>',
            },
        },
    },
    permian: {
        title: "В этом году в Пермском крае выборы губернатора",
        name: "Кандидат от ЛДПР Постников Олег Сергеевич",
        description: "55 лет, имеет высшее образование, депутат Законодательного Собрания Пермского края. Женат, воспитывает сына и дочь.",
        photo: '<img src="/src/img/Пермь-Губернатор-Постников.png" alt="Постников">',
        url: {
            vk: '<a href="https://vk.com/postnikov.ldpr" target="_blank">Вконтакте</a>',
            fb: '<a href="https://www.facebook.com/postnikov.ldpr" target="_blank">Фэйсбук</a>',
            inst: '<a href="https://www.instagram.com/postnikov.ldpr/?hl=ru" target="_blank">Инстаграм</a>'
        },
        second: {
            title: "И выборы депутата Законодательного Собрания Пермского края",
            name: "Кандидат от ЛДПР Балашов Георгий Валерьевич",
            description: "38 лет, женат, воспитывает сына и двух дочерей. Закончил военный Пермский ВКИУ, возглавляет отдел ЖКХ Ленинского района Перми.",
            photo: '<img src="/src/img/Пермь-ЗС-Балашов.png" alt="Балашов">',
            url: {
            },
        },
    },
    tatarstan: {
        title: "В этом году в республике Татарстан выборы губернатора",
        name: "Кандидат от ЛДПР Сурчилов Владимир Валерьевич",
        description: "Координатор Татарстанского регионального отделения ЛДПР, 43 года. Высшее образование, в ЛДПР 5 лет.",
        photo: '<img src="/src/img/Татарстан-Сурчилов.png" alt="Сурчилов">',
        url: {
            vk: '<a href="https://vk.com/vova_c" target="_blank">Вконтакте</a>',
        },
        second: {
            title: "И выборы депутата Государственной думы",
            name: "Кандидат от ЛДПР Колосов Андрей Васильевич",
            description: "Директор благотворительного фонда поддержки и сопровождения одаренных детей «Ингениум».",
            photo: '<img src="/src/img/Татарстан-ГД-Колосов.png" alt="Колосов">',
            url: {
                vk: '<a href="https://vk.com/a.kolosov2020" target="_blank">Вконтакте</a>',
            },
        },
    },
    chuvashia: {
        title: "В этом году в Чувашской Республике выбирают губернатора",
        name: "Кандидат от ЛДПР Степанов Олег Константинович",
        description: "Руководитель фракции ЛДПР в Государственном Совете Чувашской Республики. Имеет высшее образование, 52 года.",
        photo: '<img src="/src/img/Чувашия-Губернатор-Степанов.png" alt="Степанов">',
        url: {
            vk: '<a href="https://vk.com/lakost86" target="_blank">Вконтакте</a>',
            fb: '<a href="https://www.facebook.com/KOStepanov" target="_blank">Фэйсбук</a>',
            inst: '<a href="https://www.instagram.com/121konstantin/" target="_blank">Инстаграм</a>'
        },
        second: {
            url: {

            },
        }
    },
    kamchatka: {
        title: "В этом году в Камчатском крае выбирают губернатора",
        name: "Кандидат от ЛДПР Калашников Валерий Юрьевич",
        description: "В ЛДПР 9 лет, высшее образование, депутат Законодательного Собрания Камчатского края, координатор Камчатского регионального отделения ЛДПР, 41 год.",
        photo: '<img src="/src/img/Камчатка-Губернатор-Калашников.png" alt="Калашников">',
        url: {
            vk: '<a href="https://vk.com/v.kalashnikov79" target="_blank">Вконтакте</a>',
        },
        second: {
            url: {

            },
        }
    },
    hmao: {
        title: "В этом году в республике  выбирают губернатора",
        name: "Кандидат от  Андрей ",
        description: "Депутат МО ГО  Отец троих , бывший ",
        url: {
            vk: "vk.com",
            fb: "fb.com",
            inst: "null",
        },
        second: {
            url: {

            },
        }
    },
    krasnodar: {
        title: "В этом году в Краснодарском крае выбирают губернатора",
        name: "Кандидат от ЛДПР Тутушкин Иван Геннадьевич",
        description: "43 года, депутат Заксобрания Краснодарского края, координатор Краснодарского отделения ЛДПР. Окончил НГТУ, кандидат технических наук, офицер, женат, воспитывает пятерых детей.",
        photo: '<img src="/src/img/Краснодар-Губернатор-Тушкин.png" alt="Тутушкин">',
        url: {
            vk: '<a href="https://vk.com/tutushkinig" target="_blank">Вконтакте</a>',
        },
        second: {
            url: {

            },
        }
    },
    rostov: {
        title: "В этом году в Ростовской области выбирают губернатора",
        name: "Кандидат от ЛДПР Пятибратов Пётр Владимирович",
        description: "Координатор Ростовского регионального отделения ЛДПР. Занимается благотворительностью: помогает ветеранам Донского края, детскому дому в городе Шахты, участвовал в восстановлении храма в Ростове.",
        photo: '<img src="/src/img/Ростов-Пятибратов.png" alt="Журавлев">',
        url: {
            vk: '<a href="https://vk.com/id558234817" target="_blank">Вконтакте</a>',
            fb: '<a href="https://www.facebook.com/ppldpr" target="_blank">Фэйсбук</a>',
            inst: '<a href="https://www.instagram.com/petr_paytibratov_ldpr/" target="_blank">Инстаграм</a>'
        },
        second: {
            url: {

            },
        }
    },
    sevastopol: {
        title: "В этом году Севастополе выборы губернатора",
        name: "Кандидат от ЛДПР Журавлев Илья Григорьевич",
        description: "Координатор регионального отделения ЛДПР, депутат Законодательного собрания города Севастополя, отец.",
        photo: '<img src="/src/img/Журавлев.svg" alt="Журавлев">',
        url: {
            fb: '<a href="https://www.facebook.com/iliayzhuravlev" target="_blank">Фэйсбук</a>',
            inst: '<a href="https://instagram.com/il_zhuravlyov?igshid=a6oowq89d2yt" target="_blank">Инстаграм</a>',
        },
        second: {
            title: "И выборы депутата Заксобрания города Севастополя",
            name: "Кандидат от ЛДПР Гацолаев Сергей Викторович",
            description: "Уроженец Севастополя, выпускник военно-морского училища, завершил службу в звании майора. Участвовал в присоединении Крыма, будучи начальником штаба ополчения Ленинского района. В 2014 году вступил в ЛДПР.",
            photo: '<img src="/src/img/Севастополь-Заксоб-Гацолаев.png" alt="Колосов">',
            url: {
                vk: '<a href="https://vk.com/ldpr_sev" target="_blank">ВКонтакте</a>',
                fb: '<a href="https://www.facebook.com/profile.php?id=100043848290807" target="_blank">Фэйсбук</a>',
                inst: '<a href="https://instagram.com/gatsolaevsergei?igshid=e6nrq60x4gc9" target="_blank">Инстаграм</a>',
            },
        },
    },
}

cities.addEventListener('click', showDeputy);
cities.addEventListener('click', showDiv);


function showDiv() {
    let depatyDiv = document.querySelector("#carddep");
    depatyDiv.style.visibility = "visible";
}


function showDeputy(event) {

    let targetClickId = event.target.id;

    let firstList = document.querySelector("#first-list");
    let title = document.querySelector(".depaty-list__title h2");
    let name = document.querySelector(".cards__title");
    let description = document.querySelector(".cards__description");
    let photo = document.querySelector(".depaty-list__photo");
    let vk = document.querySelector("#vk");
    let fb = document.querySelector("#fb");
    let inst = document.querySelector("#inst");


    let secondList = document.querySelector("#second-list");
    let secondTitel = document.querySelector("#second-titel");
    let secondName = document.querySelector("#second-name");
    let secondDescription = document.querySelector("#second-description");
    let secondPhoto = document.querySelector("#second-photo");
    let secondVk = document.querySelector("#second-vk");
    let secondFb = document.querySelector("#second-fb");
    let secondInst = document.querySelector("#second-inst");

    let thirdList = document.querySelector("#third-list");

    if (depatylist[targetClickId].photo == undefined) {
        firstList.style.display = 'none';
    } else {
        firstList.style.display = 'block';
    }

    if (depatylist[targetClickId].second.photo == undefined) {
        secondList.style.display = 'none';
    } else {
        secondList.style.display = 'block';
    }

    if (depatylist[targetClickId].third == undefined) {
        thirdList.style.display = 'none';
    } else {
        thirdList.style.display = 'block';
    }



    if (depatylist[targetClickId].url.inst == undefined) {
        inst.style.display = 'none';
    } else {
        inst.style.display = 'block';
    }

    if (depatylist[targetClickId].url.vk == undefined) {
        vk.style.display = 'none';
    } else {
        vk.style.display = 'block';
    }

    if (depatylist[targetClickId].url.fb == undefined) {
        fb.style.display = 'none';
    } else {
        fb.style.display = 'block';
    }



    if (depatylist[targetClickId].second.url.inst == undefined) {
        secondInst.style.display = 'none';
    } else {
        secondInst.style.display = 'block';
    }

    if (depatylist[targetClickId].second.url.vk == undefined) {
        secondVk.style.display = 'none';
    } else {
        secondVk.style.display = 'block';
    }

    if (depatylist[targetClickId].second.url.fb == undefined) {
        secondFb.style.display = 'none';
    } else {
        secondFb.style.display = 'block';
    }

    title.innerText = depatylist[targetClickId].title;

    name.innerText = depatylist[targetClickId].name;
    description.innerText = depatylist[targetClickId].description;
    photo.innerHTML = depatylist[targetClickId].photo;
    vk.innerHTML = depatylist[targetClickId].url.vk;
    fb.innerHTML = depatylist[targetClickId].url.fb;
    inst.innerHTML = depatylist[targetClickId].url.inst;

    secondTitel.innerText = depatylist[targetClickId].second.title;
    secondName.innerText = depatylist[targetClickId].second.name;
    secondDescription.innerText = depatylist[targetClickId].second.description;
    secondPhoto.innerHTML = depatylist[targetClickId].second.photo;
    secondVk.innerHTML = depatylist[targetClickId].second.url.vk;
    secondFb.innerHTML = depatylist[targetClickId].second.url.fb;
    secondInst.innerHTML = depatylist[targetClickId].second.url.inst;


    thirdList.innerHTML = depatylist[targetClickId].third.container;

}


